import { useContext, useState } from 'react'
import Footer from '../components/footer'
import { QuizContextType } from '../context/quiz-context/quiz-context.interface'
import { QuizContext } from '../context/quiz-context/index.context'
import Button from '../components/button'

function ThanTrong() {
  const { data, saveData } = useContext(QuizContext) as QuizContextType
  const [isPrepareNext, setIsPrepareNext] = useState(false)
  const [chart, setChart] = useState(0)
  const [hidden, setHidden] = useState(true)

  const handleReset = () => {
    saveData({
      stepIndex: 0,
      score: undefined,
      prevStepIndex: 0,
      customerData: undefined,
      questions: [],
      currentQuestion: 0,
    })
  }

  const handleLoadMore = () => {
    setHidden(!hidden)
  }
  return (
    <div className="mx-auto max-w-full" style={{ width: '1024px' }}>
      <div className="mx-auto py-6 px-6 container">
        <div>
          <div
            className="mx-auto result-banner"
            style={{ backgroundImage: `url('/img/thantrong-banner.png')` }}
          >
            <div className="tt font-helve-light" style={{ color: '#d73f2b' }}>
              Phong cách đầu tư
              <br />
              của bạn là
            </div>
            <div
              className="tt-large font-helve-light"
              style={{ color: '#d73f2b' }}
            >
              Thận trọng
            </div>
          </div>
        </div>
        <div className="result-main mt-7 mb-10 grid grid-cols-1 gap-x-12 gap-y-8 md:grid-cols-5 overflow-auto m">
          <div className="wrap-result-content md:col-span-2">
            <div
              className={`result-content font-helve-light ${hidden ? 'hid' : ''
                }`}
            >
              <p className="mb-3">
                Mỗi bước đi, mỗi rủi ro phải nằm trong vùng kiểm soát của bạn.
                Dù chấp nhận rủi ro sẽ xuất hiện, nhưng điều bạn mong muốn nhất
                là sự cân bằng trong kế hoạch đầu tư. Phong cách đầu tư thận
                trọng sẽ phù hợp nhất với bạn.
              </p>
              <p className="mb-3">
                Quỹ VINACAPITAL-VIBF là lựa chọn đầu tư bạn nên cân nhắc.
              </p>
              <p>
                Quỹ VINACAPITAL-VIBF đầu tư cân bằng giữa cổ phiếu và trái phiếu
                giúp nhà đầu tư tiếp cận những cổ phiếu tiềm năng tăng trưởng
                đồng thời đầu tư vào trái phiếu để giảm thiểu rủi ro biến động
                thị trường.
              </p>
            </div>
            <div className="loadmore" onClick={handleLoadMore}>
              <span className="circle">
                {hidden ? (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                    <path d="M310.6 246.6l-127.1 128C176.4 380.9 168.2 384 160 384s-16.38-3.125-22.63-9.375l-127.1-128C.2244 237.5-2.516 223.7 2.438 211.8S19.07 192 32 192h255.1c12.94 0 24.62 7.781 29.58 19.75S319.8 237.5 310.6 246.6z" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                    style={{ paddingTop: '5px' }}
                  >
                    <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224z" />
                  </svg>
                )}
              </span>
            </div>
          </div>
          <div className="md:col-span-3">
            <div className="mb-3 flex">
              <div className="flex items-center mr-8 mb-4">
                <input
                  id="radio1"
                  type="radio"
                  name="radio1"
                  className="hidden"
                  checked={chart === 0}
                  onClick={() => setChart(0)}
                />
                <label
                  htmlFor="radio1"
                  className="flex text-sm items-center cursor-pointer"
                >
                  <span className="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
                  VINACAPITAL-VIBF
                </label>
              </div>
            </div>
            <div className="">
              {chart === 0 && (
                <img
                  src="img/vibf.png"
                  className="mx-auto"
                  style={{ height: '300px' }}
                />
              )}
            </div>
          </div>
        </div>

        <Footer
          nextText="TÌM HIỂU THÊM"
          className="md:relative fixed md:px-0 bottom-0 left-0 md:flex justify-center md:gap-3 md:bottom-auto md:left-auto"
          prevElement={
            <Button
              variant="secondary"
              className="font-helve-md w-1/2"
              onClick={handleReset}
            >
              <span className="text-sm text-primary">QUAY LẠI</span>
            </Button>
          }
          nextElement={
            <a
              href="https://wm.vinacapital.com/investment-solutions/onshore-funds/vibf/"
              target="_blank"
              className="w-1/2 md:w-auto"
              rel="noreferrer"
            >
              <Button id="mbutton" className="font-helve-md w-full" variant="primary">
                <span className="text-sm">TÌM HIỂU THÊM</span>
              </Button>
            </a>
          }
          prevText="QUAY LẠI"
          alignCenter={true}
        />
      </div>
    </div>
  )
}

export default ThanTrong
